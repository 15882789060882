.payment-container {
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
}

.adyen-checkout__field__exp-date_hint {
  display: none;
}

.adyen-checkout-input__inline-validation--valid {
  top: 15px !important;
}

.adyen-checkout__field--securityCode {
  &.adyen-checkout__field--storedCard {
    .adyen-checkout__input-wrapper {
      margin-top: 9px !important;
    }
  }
}

.adyen-checkout-form-instruction {
  display: none;
}

.adyen-order-button {
  margin-left: 0px;
  width: 250px;
}
.adyen-checkout__card__brands {
  height: 20px !important;
}
