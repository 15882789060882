.navigation {
  display: none;
  position: sticky;
  z-index: 9998;
  top: 96px;
  flex-wrap: nowrap !important;
  margin-left: 0 !important;
  border-bottom: 1px solid $Inactive-Gray;
  overflow-y: hidden;
  z-index: 9998;
  padding: 0;
  margin: 0;
  width: 100%;
  background: $Mashed-Potato-Gray;
  margin-top: 0;

  @media #{$mobile-only} {
    overflow-y: hidden;
    overflow-x: scroll;
    display: block;
  }

  @media #{$breakpoint-between-mob-tablet} {
    overflow-y: hidden;
    overflow-x: scroll;
    display: block;
  }

  .navigation-list {
    padding-left: 0;
    display: flex;
    margin-top: 6px;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .navigation-item {
    display: flex;
    margin-left: 20px;
    height: 33px;

    a {
      font-family: $National2Regular;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: -0.1px;
      color: $Dark-Gray;
      text-decoration: none;
      padding-bottom: 32px;
      white-space: nowrap;
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }
    }
    & a.current {
      font-family: $National2Medium;
      font-weight: 500;
      letter-spacing: -0.1px;
      background-color: transparent;
      color: $light-black-color;
      text-decoration: none;
      border-bottom: 2px solid $Brand-Black;
    }

    & a.current::after {
      display: none;
    }
  }
}
