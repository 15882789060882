.orderProcessingPage,
.orderSummaryPage,
.trackOrderPage,
.order-history {
  &.au {
    padding: 0px;

    .orderSummaryContainer {
      padding: 40px 0px 40px;
      margin: auto;
      justify-content: center;
      max-width: 1180px;

      &.pickup {
        padding: 40px 0px;

        @media #{$breakpoint-below-tablet} {
          padding: 40px 10px;
        }
      }

      @media #{$breakpoint-below-tablet} {
        padding: 15px 10px 20px;
        max-width: 375px;
      }

      .leftPanel {
        margin: 0px;
        padding: 0px 10px 0px 0px;

        .delivery-raider-info {
          .rgt-sec {
            margin: 0;
          }
        }

        @media #{$breakpoint-below-tablet} {
          padding: 0px;
          max-width: 355px;
          justify-content: center;
          vertical-align: top;
        }

        &.pickup {
          max-width: 790px;
          @media #{$breakpoint-below-desktop-above-tablet} {
            max-width: 55%;
          }
          .pickupOrder-collection-method-container {
            margin-top: 40px;
            padding: 0px;

            .pickupOrder-collection-method-header {
              font-family: $National2Condensed;
              font-style: normal;
              font-weight: 700;
              font-size: 24px;
              line-height: 24px;
              text-align: center;
              letter-spacing: 0.5px;
              text-transform: uppercase;
              color: $Brand-Black;
              padding: 0px 35px;
            }

            .pickupOrder-collection-method {
              margin-top: 30px;
              padding: 0px;

              .pickupOrder-collection-method-node {
                padding: 20px;
                margin-top: 10px;
                background: $white;
                box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.15);
                border-radius: 4px;
                border: 1px solid $Inactive-Gray;
                box-sizing: border-box;
                align-items: center;
                display: flex;

                &:hover {
                  background: $Mashed-Potato-Gray;
                }

                .methodDetails {
                  margin: 0px 20px;
                  width: 89%;
                }

                .methodIcon {
                  width: 25px;
                  height: 35px;
                }

                .methodTitle {
                  font-family: $National2Medium;
                  font-style: normal;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 24px;
                  color: $Brand-Black;
                  display: flex;

                  .methodTitle-Unavailable {
                    font-family: $National2Condensed;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 20px;
                    text-align: center;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                    color: $Brand-Black;
                    border: 1px solid $Brand-Black;
                    box-sizing: border-box;
                    border-radius: 30px;
                    width: fit-content;
                    padding: 0px 11px;
                    margin-left: 10px;
                  }
                }

                .methodMessage {
                  font-family: $National2Medium;
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 24px;
                  letter-spacing: -0.1px;
                  color: $Dark-Gray;
                }

                .arrowIcon {
                  float: right;
                }
              }
            }
          }
        }

        &.catering {
          max-width: 780px;
          @media #{$breakpoint-below-desktop-above-tablet} {
            max-width: 55%;
          }
        }
        @media #{$breakpoint-below-desktop-above-tablet} {
          max-width: 55%;
        }
      }

      .rightPanel {
        margin: 0px;
        padding: 0px 0px 0px 10px;
        max-width: 579px;
        @media #{$breakpoint-below-tablet} {
          padding: 10px 0px 0px;
          max-width: 355px;
          margin: auto;
          justify-content: center;
          margin-top: 20px;
        }

        &.pickup {
          max-width: 390px;
          @media #{$breakpoint-below-desktop-above-tablet} {
            max-width: 35%;
          }
        }

        &.catering {
          max-width: 390px;
          margin-top: 20px;
          @media #{$breakpoint-below-desktop-above-tablet} {
            max-width: 35%;
          }
          @media (max-width: 1169px) {
            max-width: 100%;
          }
        }
        @media #{$breakpoint-below-desktop-above-tablet} {
          max-width: 35%;
        }
      }
      .pickupOrderSupportPanel {
        display: flex;
        justify-content: end;
        padding: 0px;
        padding-top: 40px;
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 22px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: $Brand-Black;

        @media #{$breakpoint-below-tablet} {
          padding: 0px;
        }

        @media #{$mobile-only} {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-top: 30px;
        }
      }
      .pickupOrderCancelPanel {
        padding: 0px;
        padding-top: 40px;
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 22px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: $Brand-Black;

        @media #{$breakpoint-below-tablet} {
          padding: 0px;
        }
        @media #{$mobile-only} {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-top: 30px;
        }

        .pickupOrderCancelPanelBtn {
          font-family: $National2Medium;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          text-decoration-line: underline;
          color: $Brand-Black;
          float: right;
          cursor: pointer;
          text-transform: none;
          letter-spacing: normal;
          @media #{$mobile-only} {
            margin-top: 7px;
          }
        }
      }

      .bottomPanel {
        margin-top: 40px;
        padding: 0px;

        .linebreak {
          margin: 0px;
        }
      }

      .orderSummaryStatusContainer {
        padding: 0px;

        @media #{$breakpoint-below-tablet} {
          height: auto;
        }

        &.pickup {
          height: auto;

          .estimated-arrival-refund,
          .idAndTime {
            font-size: 24px;
            line-height: 24px;
          }
        }

        .orderSummaryStatusPanel {
          padding: 0px;
          margin-bottom: 70px;

          @media #{$breakpoint-below-tablet} {
            margin-bottom: 50px;
          }

          &.pickup {
            height: auto;

            .allSet {
              letter-spacing: 2px;
              text-transform: uppercase;
              margin-bottom: 0px;
              overflow-wrap: break-word;
              &.drive-thru,
              &.in-store {
                .statusMessage {
                  @media #{$breakpoint-desktop-small} {
                    margin: 0% 15.7%;
                  }
                  @media #{$breakpoint-below-tablet} {
                    margin: 0%;
                  }
                  @media #{$breakpoint-below-iphoneX} {
                    margin: 0%;
                  }
                }
              }
            }

            .pickup-collection-method-ready-to-prepare {
              font-family: $National2Condensed;
              font-style: normal;
              font-weight: 700;
              font-size: 24px;
              line-height: 24px;
              letter-spacing: 0.5px;
              text-transform: uppercase;
              color: $Brand-Black;

              .linebreak {
                margin: 30px 0px;
              }

              .button {
                margin-top: 30px;
                background: $Brand-Red;
                border-radius: 200px;
                padding: 10px 40px;
                font-family: $National2Regular;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                float: none;
                line-height: 24px;
                text-align: center;
                color: $white;
                border-style: none;
                width: 254px;
                justify-self: center;
              }
            }

            .change-collection-method {
              font-family: $National2Medium;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 24px;

              .change-collection-method-btn {
                border: none;
                box-shadow: none;
                text-decoration-line: underline;
                color: $Brand-Black;
                float: none;
                cursor: pointer;
                text-transform: none;
                letter-spacing: normal;
                text-align: center;
                margin-top: 15px;

                &:before {
                  background-color: transparent;
                }
              }
            }
          }

          .pickup-store-order,
          .catering-store-order {
            font-family: $National2Condensed;
            font-style: normal;
            font-weight: 700;
            font-size: 20px;
            line-height: 22px;

            text-align: center;
            letter-spacing: 1px;
            text-transform: uppercase;

            color: $Dark-Gray;

            &.red {
              color: $Brand-Red;
            }
          }

          .statusIcon {
            margin-top: 30px;

            @media #{$breakpoint-below-tablet} {
              margin-top: 25px;
            }
          }
        }

        .idAndTime {
          font-size: 38px;
          line-height: 43px;
          letter-spacing: 1px;
          text-transform: uppercase;

          @media #{$breakpoint-below-tablet} {
            font-size: 20px;
          }
        }

        .allset-delivery {
          .allSet-delivery-refund {
            margin-top: 40px;
            font-family: $National2Regular;
            font-size: 20px;
            font-style: normal;
            line-height: 24px;
            letter-spacing: 1px;
            text-align: center;

            @media #{$breakpoint-below-tablet} {
              margin-top: 20px;
              padding: 0px 10px;
            }
          }
        }

        .progress-bar-list {
          margin: auto;
          justify-content: center;
          margin-top: 30px;

          .ProgressDelivery {
            width: 11%;
            margin: 0px 3px;

            @keyframes resize-subProgressDelivery {
              from {
                width: 0%;
              }

              to {
                width: 100%;
              }
            }

            .subProgressDelivery {
              height: 100%;
              border-radius: 6px;

              background-color: $Brand-Red;

              &.play {
                animation-duration: 5s;
                animation-name: resize-subProgressDelivery;
                animation-iteration-count: infinite;
                animation-play-state: running;
              }

              &.half {
                width: 50%;
              }

              &.full {
                width: 100%;
              }

              &.none {
                width: 0px;
              }
            }
          }
        }
      }

      .googleMapContainer {
        min-height: 640px;
      }

      .order-processing-info-accordion {
        margin: 0px;
        padding: 0px;
      }

      .postorder-payment-details {
        height: 50px;
      }
      .number {
        background: #e3e3e3;
        border-radius: 4px;
        margin-right: 3px;
        padding: 4px 8px;
        margin-left: 5px;
        font-size: 12px;
        line-height: 22px;
        color: #202124;
        font-weight: 400;
        font-family: $National2Medium;
      }
    }
    .saveYourOrdersAndCreateKFCAccountContainer {
      padding: 0px;
      padding-top: 20px;
      margin: auto;
      //text-align: center;

      .savingdiv {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        padding-top: 20px;

        .saving {
          text-transform: uppercase;
          font-family: $National2Condensed;
          font-style: normal;
          font-weight: bold;
          font-size: 20px;
          line-height: 22px;
          letter-spacing: 1px;
          margin-right: 40px;

          @media #{$breakpoint-below-tablet} {
            margin-right: 0px;
            margin-bottom: 20px;
            width: 226px;
            text-align: center;
          }
        }

        @media #{$breakpoint-below-tablet} {
          display: flex;
          flex-direction: column;
        }
      }
    }

    .postorder-payment-details {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    @media only screen and #{$mobile-only} {
      .save-order-modal {
        .modal-content {
          width: 100% !important;

          .modal-body {
            padding: 0 0 !important;
          }
        }

        .modalContants {
          .inputbox {
            margin-left: 3%;
          }

          .buttonItems {
            margin-top: 10.5% !important;
          }
        }

        .fontstyle {
          font-size: 19px;
          letter-spacing: 0.2px;
        }
      }
    }

    @media only screen and #{$breakpoint-between-mob-tablet} {
      .save-order-modal {
        .modal-content {
          width: 60% !important;
          top: 30%;
          left: 20%;

          .modal-body {
            padding: 0 0 !important;
          }
        }

        .modalContants {
          .inputbox {
            margin-left: 3%;
          }

          .buttonItems {
            margin-top: 10.5% !important;
          }
        }
      }
    }

    .catering-order-deliver-details {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 24px;
      text-align: center;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      color: $Brand-Black;
      margin: 0px 160px;
      margin-top: 30px;
      @media #{$breakpoint-below-tablet} {
        font-size: 20px;
        letter-spacing: 1px;
        margin: 30px 50px 0;
      }
      @media #{$breakpoint-iphone12-size} {
        margin: 30px 50px 0;
      }
      @media #{$breakpoint-below-include-iphone5} {
        margin: 30px 42px 0;
      }
      @media #{$breakpoint-below-include-iphone5SE} {
        margin: 30px 30px 0;
      }
    }

    .catering-confirmation-email-details {
      font-family: $National2Regular;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      margin: 30px 150px;
      letter-spacing: 0.5px;
      @media #{$breakpoint-below-tablet} {
        margin: 20px 13px 0px 17px;
        text-transform: none;
      }
      @media #{$breakpoint-iphone12-size} {
        margin: 20px 10px 0px 13px;
      }
      @media #{$breakpoint-below-include-iphone5} {
        margin: 20px 5px 0px 10px;
      }
    }
  }

  .pickupCancelOrderBaseModal,
  .pickupClosedInfoModalBaseModal,
  .pickupClosingSoonModalBaseModal {
    .modal-content {
      height: 434px;
      width: 580px;
      left: 30%;
      box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.15);
      border-radius: 10px;

      @media #{$breakpoint-below-tablet} {
        height: 60%;
        width: 100%;
        border-radius: 10px 10px 0px 0px;
        position: fixed;
        top: auto;
        right: auto;
        left: auto;
        bottom: 0;
      }

      .modal-header {
        border-bottom: none;

        .custom-modal-close {
          top: 5%;
          z-index: 1;
        }

        .custom-modal-close:focus-visible {
          outline: none;
        }
      }

      .modal-body {
        background: $white;
        box-shadow: none;
      }
    }

    .pickupCancelOrderModal,
    .pickupGeoLocationVerifyModal,
    .closingsoon-wrapper {
      margin: 30px 0px;
      text-align: center;

      @media #{$breakpoint-below-tablet} {
        margin: 20px 0px;
      }

      .message-cancel {
        margin-top: 15px;
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 22px;
        text-align: center;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: $Brand-Black;
        padding: 0px 150px;

        @media #{$breakpoint-below-tablet} {
          padding: 0px 40px;
        }
      }

      .message-refund {
        margin-top: 15px;
        font-family: $National2Regular;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        letter-spacing: -0.1px;
        color: $Brand-Black;
        padding: 0px 160px;

        @media #{$breakpoint-below-tablet} {
          padding: 0px 65px;
        }
      }
      .selected-store-name {
        color: #e4002b;
      }

      .message-refund.ahead-time {
        margin-top: 0;
      }
      .closing-msg.message-refund {
        padding: 0px 144px;
        margin-top: 25px;
      }
      .last-checkin.message-cancel {
        padding: 0px 24%;
      }

      .buttonItems {
        margin-top: 14px;
        justify-content: center !important;

        .closebtn-cls {
          margin-right: 20px;
        }

        .button {
          padding: 11px 35px;
        }
      }
    }

    .pickupGeoLocationVerifyModal {
      .message-refund {
        padding: 0px 150px;

        @media #{$breakpoint-below-tablet} {
          padding: 0px 40px;
        }
      }
    }
  }

  #curbsidePlateNumberInputModal {
    .modal-content {
      height: 300px;
    }

    .curbside-plate-number {
      margin-top: 0;

      .message-cancel {
        margin-top: 0;
        padding: 0 100px;

        @media #{$mobile-only} {
          padding: 0 30px;
        }

        .vehicle-details-title {
          font-size: 24px;
          margin-bottom: 30px;
        }

        .material-input.field input:placeholder-shown + label,
        .material-input.field input:not(:placeholder-shown) + label {
          font-family: $National2Regular;
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          letter-spacing: 0;
          text-align: left;
          text-transform: none;
          padding-bottom: 4px;
        }

        .material-input.field input:not(:placeholder-shown) + label {
          font-size: 14px;
        }

        .material-input.field input:focus + label {
          font-size: 15px;
          line-height: 24px;
        }

        .curbside-plate-number-input {
          background-color: $white;
          font-size: 16px;
          padding-bottom: 4px;
          text-transform: none;
        }
      }

      .buttonItems {
        margin-top: 60px;
      }
    }
  }

  .order-processing-info-container {
    &.pickup,
    &.catering {
      .preparedByPanel {
        .get-direction-text {
          font-family: $National2Regular;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
          text-decoration: underline;
          cursor: pointer;
        }

        .get-direction-text-jp {
          top: 15px;
          position: relative;
        }

        .get-direction-icon {
          padding-right: 10px;
        }

        .get-direction-icon-jp {
          padding-top: 25px;
        }

        .get-direction-panel {
          float: right;
        }

        .hrs-view {
          white-space: pre;
          padding-left: 0px;
          display: block;
        }
        .phone-details {
          margin-top: 8px;
        }
      }
    }
    &.delivery {
      .place {
        display: flex;
      }
      .hrs-view {
        margin-left: 10px;
      }
    }
  }
  .substatusmsg {
    display: flex;
    flex-direction: column !important;
    align-self: stretch;
    font-family: $National2Medium;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    text-align: center;
    letter-spacing: -0.1px;
    margin: 16px;
  }
  .substatusmsgDelivered span {
    color: $white !important;
  }

  .emailConfirmTxt {
    font-family: $National2Regular;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
  }
  .emailconfirmation-container {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    text-align: center;
    color: $Brand-Black;
    font-size: 14px;
    font-family: $National2Regular;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.1px;
    overflow-wrap: break-word;
  }
}

.ordersummaryInfo-icon {
  .statusIcon {
    width: 70px !important;
  }
}
.iframe-ordertrackingmaps {
  width: 100%;
  height: 100%;
  border: none !important;
  @media #{$mobile-only} {
    height: 400px !important;
  }
  @media #{$breakpoint-below-tablet} {
    height: 400px !important;
  }
}
.phone-details-text {
  font-family: $National2Regular;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 150%;
  color: #202124;
  text-decoration: underline;
}
.phone-details {
  a:hover {
    color: #202124 !important;
  }
}
.orderdirection-icon {
  margin-top: 16%;
  @media #{$breakpoint-below-tablet} {
    margin-top: 8%;
  }
}
.pos-completed-scheduleorder {
  height: auto !important;
  .allset-delivery {
    margin: 30px 0px 40px !important;
  }
}

.order-checkedin {
  height: 550px !important;

  .allset-delivery {
    margin: 30px 0px 40px !important;
  }
}
.dasher-dropoff-updated {
  .allset-delivery {
    margin: 30px 0px 40px !important;
  }
}
.favorites-hyperlink-style {
  color: white !important;
  font-weight: normal;
  font-size: 16px;
}
.subheadline-content {
  display: block;
}
.subheadline-content-two {
  text-transform: uppercase;
  font-weight: 600;
  width: 50%;
  margin: 1rem auto;
}
.atTextColor {
  color: $black !important;
}
