@mixin notify-alert {
  color: $Brand-Black;
  font-family: $National2Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.1px;
  border-radius: 4px;
  display: flex;
  padding: 11px;
  width: 95%;
  margin-bottom: 1%;
  margin-left: 7px;
}
@mixin notify-alert-wicode {
  color: $Brand-Black;
  font-family: $National2Regular;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.1px;
  border-radius: 4px;
  display: flex;
  padding: 11px;
  width: 100%;
}
@mixin alert-icon {
  width: 15px;
  height: 15px;
  margin: -1.5px 8px 4px 0px;
}

@mixin fontStyleCartSummaryCard {
  font-family: $National2Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.1px;
  color: $Dark-Gray;
}
@mixin fontStyleCheckoutSummaryCardWicode {
  display: flex;
  font-family: $National2Medium;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  letter-spacing: -0.1px;
  color: $gray-lightbg;
  text-transform: capitalize;
}

@mixin cartSummaryErrorContainer {
  background: #fff4f2;
  border-radius: 4px;
  margin: -10px 40px 30px 40px;
  display: flex;
  flex-direction: row;
}

@mixin cartErrorIcon {
  width: 15px;
  height: 15px;
  margin-left: 10px;
  margin-right: 5px;
}

@mixin cartErrorMessage {
  font-family: $National2Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.1px;
  color: #202124;
}

.cart-page-container-desktop {
  background-color: $white;

  .cart-content-container-desktop {
    display: flex;
    //margin: 0 7.5%;

    .cart-summary-card-container {
      box-shadow: 0 4px 9px rgba(0, 0, 0, 0.15);
      border-radius: 10px;
      background: #ffff;
      margin-left: 40px;
      //padding: 40px 40px;
      height: fit-content;
      width: 360px;
      margin-bottom: 170px;
      margin-top: 25px;
      padding-bottom: 5px;
      .cart-summary-item-count {
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 24px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        columns: $Brand-Black;
        margin-bottom: 20px;
        padding-top: 40px;
        padding-left: 31px;
      }

      .cart-summary-coupons {
        margin-bottom: 2%;
        padding-left: 10px;
        padding-right: 10px;
        & .cart-offers-container {
          padding: 0 18px 0 18px;
          & .cart-offers-link {
            width: 90%;
            & .cart-offers-text {
              width: 100%;
              font-family: $National2Medium;
              font-weight: 500;
            }
          }
          & .cart-offers-view-all {
            width: 28%;
            padding-top: 1px;
          }
        }
      }

      .cart-summary-content {
        margin-bottom: 20px;
        padding-left: 31px;
        padding-right: 31px;
        .cart-summary-content-item {
          display: flex;
          justify-content: space-between;

          .cart-summary-content-item-name {
            @include fontStyleCartSummaryCard();
            letter-spacing: unset;
            width: 208px;
            text-transform: capitalize;

            .cart-discount-code {
              white-space: nowrap;
              font-weight: 500;
              margin-right: 5%;
            }

            .cart-discount-clear-icon {
              vertical-align: middle;
              cursor: pointer;
            }
          }
          .cart-summary-content-item-name-wicode {
            @include fontStyleCheckoutSummaryCardWicode();
            letter-spacing: unset;
            width: 208px;
            text-transform: capitalize;

            .cart-discount-code {
              white-space: nowrap;
              font-weight: 500;
              margin-right: 5%;
            }

            .cart-discount-clear-icon {
              vertical-align: middle;
              cursor: pointer;
            }
          }
          .cart-summary-content-item-value {
            font-family: $National2Regular;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: $Brand-Black;
          }
        }

        hr {
          background: $gray-lighter;
          width: 100%;
        }
        .cart-summary-details {
          .cart-summary-gst {
            @include fontStyleCartSummaryCard();
          }
          .cart-summary-additional-details {
            @include fontStyleCartSummaryCard();
          }
        }

        .flexBetweens {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          margin-bottom: 6px;
          .cart-summary-content-item-name {
            @include fontStyleCartSummaryCard();
            letter-spacing: unset;
            width: 208px;
          }
          .cart-summary-content-item-name-wicode {
            @include fontStyleCheckoutSummaryCardWicode();
            text-transform: capitalize;
            letter-spacing: unset;
            width: 208px;
          }
        }
      }
      & .cart-summary-checkout-button-container {
        padding-left: 32px;
        padding-right: 32px;
        padding-bottom: 30px;
        & .cart-summary-checkout-button {
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding: 11px 17px;
          &.cart-summary-checkout-button {
            display: flex;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;
            border-radius: 30px;
          }
          &.disabled {
            background: #dbdbdb;
            border-radius: 66.1976px;
            border: none;
            font-family: $National2Regular;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            display: flex;
            align-items: center;
            color: #494949;
            cursor: not-allowed;
            pointer-events: none;
          }
        }
        .button-contained {
          &[data-inactive='true'],
          &[data-disabled='true'],
          &[disabled] {
            width: 280px;
            margin-left: 6px;
          }
        }
      }
      .cart-summary-error-total-exceeds {
        &.overlimit-error {
          height: 54px;
          @include cartSummaryErrorContainer();
          justify-content: center;
          align-items: center;
          & .cart-summary-error-icon {
            @include cartErrorIcon();
            margin-top: -5px;
            margin-left: 20px;
            margin-right: 10px;
          }
          & .cart-summary-error-message {
            @include cartErrorMessage();
            width: 235px !important;
            padding-right: 17px !important;
          }
        }
        &.betweenlimit-error {
          height: 78px;
          @include cartSummaryErrorContainer();
          & .cart-summary-error-icon {
            @include cartErrorIcon();
            margin-top: 19px;
          }
          & .cart-summary-error-message {
            @include cartErrorMessage();
            margin-top: 15px;
          }
        }
      }
    }
  }
}

@media #{$breakpoint-below-tablet} {
  .cart-page-container-mobile {
    & .cart-summary-card-mobile {
      padding: 20px 8px;
      & .cart-summary-card-container {
        padding: 40px 12px;
        box-shadow: 0 0 9px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
        background-color: $white;

        .cart-summary-item-count {
          font-family: $National2Condensed;
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          line-height: 24px;
          letter-spacing: 1px;
          text-transform: uppercase;
          color: $Brand-Black;
          margin-bottom: 20px;
          margin-left: 14px;
        }

        .cart-summary-content {
          margin-top: 20px;
          margin-right: 14px;
          margin-left: 14px;
          display: flex;
          flex-direction: column;
          margin-bottom: 22px;
          hr {
            background: $gray-lighter;
            width: 100%;
          }

          .cart-summary-content-item {
            display: flex;
            justify-content: space-between;

            .cart-summary-content-item-name {
              @include fontStyleCartSummaryCard();
              text-transform: capitalize;
            }
            .cart-summary-content-item-name-wicode {
              @include fontStyleCheckoutSummaryCardWicode();
              text-transform: capitalize;
              .cart-discount-code {
                white-space: nowrap;
                font-weight: 500;
                margin-right: 5%;
              }
            }

            .cart-summary-content-item-name.d-flex {
              display: $d-flex;
            }

            .cart-summary-content-item-value {
              font-family: $National2Regular;
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 24px;
              text-align: right;
              color: $Brand-Black;
            }
          }
        }

        .cart-summary-details {
          .cart-summary-gst {
            @include fontStyleCartSummaryCard();
          }
          .cart-summary-additional-details {
            @include fontStyleCartSummaryCard();
          }
        }
      }
      & .cart-summary-checkout-button-container {
        .cart-summary-checkout-button {
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding: 11px 17px;
          &.cart-summary-checkout-button {
            display: flex;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;
            border-radius: 30px;
          }
          &.disabled {
            background: #dbdbdb;
            border-radius: 66.1976px;
            border: none;
            font-family: $National2Regular;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            display: flex;
            align-items: center;
            color: #494949;
            cursor: not-allowed;
            pointer-events: none;
          }
        }
        &.fixed {
          background: linear-gradient(
            360deg,
            #fff 55.13%,
            rgba(255, 255, 255, 0.7) 77.56%,
            rgba(255, 255, 255, 0) 100%
          );
        }
      }
      .cart-summary-checkout-button-mobile {
        .cart-summary-checkout-button {
          width: 90vw;
          display: flex;
          text-align: center;
          justify-content: space-between;
          margin: auto;
        }
      }

      .fixed {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
      }
      .cart-summary-error-total-exceeds {
        &.overlimit-error {
          height: 54px;
          @include cartSummaryErrorContainer();
          margin: 20px 0px -10px 0px;

          & .cart-summary-error-icon {
            width: 15px;
            height: 15px;
            margin-top: 18px;
            margin-right: 10px;
            margin-left: 20px;
          }
          & .cart-summary-error-message {
            @include cartErrorMessage();
            width: 218px;
            margin-top: 15px;
          }
        }
        &.betweenlimit-error {
          height: 78px;
          @include cartSummaryErrorContainer();
          margin: 20px 4px -10px 4px;

          & .cart-summary-error-icon {
            width: 15px;
            height: 15px;
            margin-top: 20px;
            margin-right: 10px;
            margin-left: 20px;
          }
          & .cart-summary-error-message {
            @include cartErrorMessage();
            margin-top: 15px;
            width: 26ch;
          }
        }
      }

      .flexBetweens {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-bottom: 6px;
        .cart-summary-content-item-name {
          @include fontStyleCartSummaryCard();
        }
        .cart-summary-content-item-name-wicode {
          @include fontStyleCheckoutSummaryCardWicode();
          text-transform: capitalize;
        }
      }
    }
  }
}
.checkout-summary-content-item-value {
  font-weight: 700 !important;
}

.cart-coupon-offers-container {
  height: 83px;
  position: relative;
  background-color: #f8f7f5;
  background-image: none;
  display: block;
  & .cart-offers-text {
    font-family: $National2Medium;
  }

  & :before {
    width: 14px;
    height: 10px;
    background-color: #fff;
    border-top-left-radius: 110px;
    border-top-right-radius: 110px;
    border-bottom: 0;
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(90deg);
    left: -3px;
  }
  & :after {
    width: 14px;
    height: 10px;
    background-color: #fff;
    border-top-left-radius: 110px;
    border-top-right-radius: 110px;
    border-bottom: 0;
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(-90deg);
    right: -3px;
  }

  & .cart-coupon-offers-text {
    padding-left: 30px;
    padding-bottom: 5px;
    font-family: $National2Medium;
    font-style: normal;
    font-size: 14px;
    line-height: 15px;
    color: $black;
    font-weight: 500;
    padding-top: 0px;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  & .cart-offers-link {
    display: flex;
    padding-top: 15px;
    padding-bottom: 5px;
  }
}

.store-unavailable-notification {
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  @media #{$breakpoint-below-tablet} {
    width: 100%;
    margin-right: 20px;
  }

  .notify-alert {
    background: #fff4f2;
    @include notify-alert;

    @media #{$breakpoint-below-tablet} {
      margin-left: 0px;
      width: 100%;
      padding: 15px;
    }

    & .location-alert-icon {
      margin-top: 2px !important;
      @include alert-icon;

      @media #{$breakpoint-below-tablet} {
        margin: -1.5px 8px 4px 0px;
      }
    }
  }
  .notify-alert-wicode {
    background: #fff4f2;
    @include notify-alert;

    @media #{$breakpoint-below-tablet} {
      margin-left: 0px;
      width: 100%;
      padding: 15px;
    }

    & .location-alert-icon {
      margin-top: 2px !important;
      @include alert-icon;

      @media #{$breakpoint-below-tablet} {
        margin: -1.5px 8px 4px 0px;
      }
    }
  }
}

.promos-summary-cart-notification {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  @media #{$mobile-only} {
    // margin: 0 5% 3% 0;
  }
  @media #{$breakpoint-below-tablet} {
    width: 100%;
    // margin-left: 20px;
    margin-right: 20px;
  }
  .notify-alert {
    background: $Alert-Yellow;
    @include notify-alert;

    @media #{$breakpoint-below-tablet} {
      margin-left: 0px;
      width: 100%;
      padding: 15px;
    }

    & .location-alert-icon {
      @include alert-icon;

      @media #{$breakpoint-below-tablet} {
        margin: -1.5px 8px 4px 0px;
      }
    }
    .promos-error-massage {
      color: #494949;
    }
    .textwhiteSpace {
      white-space: pre-wrap;
    }
  }
  &.guidancemessage {
    .notify-alert {
      margin: 0 auto;
    }
  }
}
.voucher-summary-cart-notification-limit-exceeded {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  @media #{$breakpoint-below-tablet} {
    width: 100%;
  }
  .notify-alert-wicode {
    background: $light-red;
    @include notify-alert-wicode();

    @media #{$breakpoint-below-tablet} {
      margin-left: 0px;
      width: 100%;
      padding: 15px;
    }

    & .location-alert-icon {
      @include alert-icon;

      @media #{$breakpoint-below-tablet} {
        margin: -1.5px 8px 4px 0px;
      }
    }
    .promos-error-massage {
      color: #494949;
    }
  }
}
.voucher-summary-cart-notification-wicode-auto-released {
  margin-bottom: 24px;
}
.voucher-summary-cart-notification-voucher-above-subtotal,
.voucher-summary-cart-notification-wicode-auto-released {
  display: flex;
  flex-direction: column;
  @media #{$breakpoint-below-tablet} {
    width: 100%;
  }
  .notify-alert-wicode {
    background: $red-lighter;
    @include notify-alert-wicode();

    @media #{$breakpoint-below-tablet} {
      margin-left: 0px;
      width: 100%;
      padding: 15px;
    }

    & .location-alert-icon {
      @include alert-icon;

      @media #{$breakpoint-below-tablet} {
        margin: -1.5px 8px 4px 0px;
      }
    }
    .promos-error-massage {
      color: #494949;
    }
  }
}
.voucher-summary-cart-notification-meal-fully-covered {
  display: flex;
  flex-direction: column;
  @media #{$mobile-only} {
    // margin: 0 5% 3% 0;
  }
  @media #{$breakpoint-below-tablet} {
    width: 100%;
  }
  .notify-alert-wicode {
    background: $light-green;
    @include notify-alert-wicode();

    @media #{$breakpoint-below-tablet} {
      margin-left: 0px;
      width: 100%;
      padding: 15px;
    }

    & .location-alert-icon {
      @include alert-icon;

      @media #{$breakpoint-below-tablet} {
        margin: -1.5px 8px 4px 0px;
      }
    }
    .promos-error-massage {
      color: #494949;
    }
  }
}
.cart-summary-coupons .cart-offers-icon {
  margin-top: -5px;
}
.Toastify__toast-container {
  width: fit-content;
  height: fit-content;
}
.cart-add-hope-image {
  width: 53px;
  height: 36px;
  object-fit: contain;
}
.cart-carry-bag-container {
  display: flex;
  align-items: center;
  margin-left: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  margin-right: 12px;
  border-bottom: 1px solid $Light-Gray;

  .cart-carry-bag-checkbox {
    margin-right: 12px;
  }

  .cart-carry-bag-header {
    font-family: $National2Regular;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: $Brand-Black;
    margin-bottom: 0;
  }
}
.cart-add-hope-container {
  border: 1px solid $Light-Gray;
  border-radius: 3px;
  padding: 15px 16px 10px 16px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  .cart-add-hope-checkbox {
    align-self: normal;
    margin-top: 5px;
  }
  @-moz-document url-prefix() {
    .cart-add-hope-checkbox {
      margin-top: 0;
    }
  }
  .cart-add-hope-content {
    margin-right: 5px;
    margin-left: 5px;

    .cart-add-hope-header {
      width: 195px;
      font-family: $National2Regular;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: $Brand-Black;
    }

    .cart-add-hope-text {
      font-family: $National2Regular;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 22px;
      color: $Dark-Gray;
    }
  }
}
