.rewards-action-modal {
  background-color: $Brand-Black;
  color: $white;
  width: 100%;
  height: 100%;
  position: relative;

  @media #{$breakpoint-above-mobile} {
    max-width: 580px;
    margin: 80px auto 80px;
    max-height: calc(100% - 160px);
    height: auto;
    overflow: auto;
  }

  &--container {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: $z-index-max + 1;
    background-color: #202124a6;
  }

  &--content {
    max-height: calc(100% - 52px);
    overflow-y: auto;

    @media #{$breakpoint-above-mobile} {
      max-height: calc(100% - 108px);
    }

    &--product-actions {
      margin: 160px 90px 44px;
      background-color: $white;
      color: $Brand-Black;
      border-radius: 0 0 8px 8px;
      box-shadow: $Brand-Red 6px 6px 0 0;
      border-top: 1px solid transparent;

      @media #{$breakpoint-above-mobile} {
        margin-top: 275px;
      }

      &-container {
        padding: 0 24px 24px;
        background-color: $white;
      }
    }
  }

  &--title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 16px;

    &-img {
      width: 20px;
      height: 20px;
      margin-right: 10px;

      @media #{$breakpoint-above-mobile} {
        margin-right: 20px;
      }
    }

    &-text {
      font-family: $National2Condensed;
      font-weight: bold;
      font-size: 18px;
      line-height: 18px;
      margin: 0;
      text-transform: uppercase;

      @media #{$breakpoint-above-mobile} {
        font-size: 20px;
        line-height: 20px;
      }
    }
  }

  &--close-btn {
    position: absolute;
    width: 16px;
    height: 16px;
    background: transparent;
    border: none;
    padding: 0;
    top: 20px;
    right: 20px;

    &-icon {
      width: 16px;
      height: 16px;
      vertical-align: top;
    }
  }

  &--content {
    padding: 16px;

    &--product-details {
      text-align: center;
    }
  }

  &--product-title {
    font-family: $National2Condensed;
    font-weight: bold;
    font-size: 36px;
    line-height: 36px;
    margin: 0;
    text-transform: uppercase;
    margin-bottom: 10px;

    @media #{$breakpoint-above-mobile} {
      font-size: 64px;
      line-height: 64px;
      margin-bottom: 24px;
    }
  }

  &--product-description {
    font-family: $National2Regular;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    margin: 0 0 10px 0;
    color: $white;

    @media #{$breakpoint-above-mobile} {
      margin-bottom: 24px;
    }
  }

  &--image {
    width: 100%;
    max-width: 100%;
    margin-bottom: 10px;
    margin-top: -175px;

    @media #{$breakpoint-above-mobile} {
      margin-top: -240px;
    }

    img {
      width: 100%;
    }
  }

  &--redemption-header {
    font-family: $National2Condensed;
    font-weight: bold;
    font-size: 20px;
    line-height: 20px;
    margin: 0;
    text-transform: uppercase;
    margin-bottom: 10px;

    @media #{$breakpoint-above-mobile} {
      font-size: 24px;
      line-height: 24px;
    }
  }

  &--banners {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 10px;
    margin-bottom: 10px;
    padding-left: 0;

    .coupon--banner {
      position: static;
      width: auto;
      margin: 0;

      &_text {
        height: 28px;
        padding: 5px 8px;
      }
    }

    .coupon--disposition--text {
      border-radius: 4px;
      border: 1px solid $Brand-Black;
      padding: 4px 8px;
    }
  }

  &--coupon-condition-list {
    font-family: $National2Regular;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    margin: 0 0 10px 0;
    padding-left: 0;
    list-style-position: inside;
    list-style-type: disc;
    color: $gray-lightbg;
    @media #{$breakpoint-above-mobile} {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &--coupon-end-date {
    font-family: $National2Regular;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    margin: 0;
    margin-bottom: 10px;
    color: $gray-lightbg;

    @media #{$breakpoint-above-mobile} {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &--coupon--price {
    font-family: $National2Condensed;
    font-weight: 700;
    font-size: 32px;
    line-height: 32px;
    letter-spacing: 0.5px;
    margin: 0;
    text-transform: uppercase;
    margin-bottom: 10px;

    @media #{$breakpoint-above-mobile} {
      font-size: 40px;
      line-height: 40px;
    }
  }

  &--button {
    display: block;
    height: 44px;
    margin: 16px auto 0;
  }

  &--alert {
    display: flex;
    gap: 10px;
    padding: 12px;
    border-radius: 3px;
    background-color: $Alert-Yellow;

    &--success {
      background-color: $light-green;
    }

    &--text {
      font-family: $National2Regular;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;

      p {
        margin: 0;
      }
    }

    &--button {
      background: none;
      border: none;
      margin: 0;
      padding: 0;
      cursor: pointer;
      font-family: $National2Regular;
      font-weight: 700;
      font-size: 14px;
      line-height: 21px;
      text-decoration: 1px underline;
    }
  }
}
