.banner {
  background-color: $Brand-Black;
  border-radius: 8px;
  box-sizing: border-box;
  color: $white;
  display: flex;
  flex-direction: row-reverse;
  font-family: $National2Condensed;
  height: 416px;
  justify-content: space-between;
  padding: 24px 16px 0;
  width: 100%;

  @media #{$breakpoint-above-tablet} {
    height: 400px;
    padding: 16px 16px 0 104px;
  }

  &--carousel {
    position: relative;

    &-indicators {
      bottom: 16px;
      display: flex;
      flex-direction: row;
      font-family: $National2Regular;
      font-size: 14px;
      font-weight: $font-weight-500;
      gap: 10px;
      justify-content: flex-end;
      list-style: none;
      margin: 0;
      padding: 0;
      position: absolute;
      right: 0;

      @media #{$breakpoint-above-tablet} {
        bottom: unset;
        gap: 8px;
        position: relative;
        margin-bottom: 24px;
        margin-top: -21px;
      }
    }

    &-indicator {
      button {
        background-color: transparent;
        border: none;
        border-radius: 30px;
        color: $Brand-Black;
        cursor: pointer;
        height: 24px;
        width: 24px;

        @media #{$breakpoint-above-tablet} {
          height: 40px;
          width: 40px;
        }
      }

      &.active {
        button {
          background-color: $Brand-Black;
          color: $white;
        }
      }
    }
  }

  &--info {
    @media #{$breakpoint-above-tablet} {
      max-width: 445px;
      padding-top: 64px;
    }
  }

  &--tags {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }

  &--pricing {
    align-items: center;
    display: flex;
    flex-direction: row;
    font-size: 24px;
    font-weight: 700;
    gap: 8px;
    letter-spacing: 0.5px;
    line-height: 1;
    margin-bottom: 8px;

    @media #{$breakpoint-above-tablet} {
      font-size: 28px;
      margin-bottom: 16px;
    }
  }

  &--reward {
    width: 70px;

    @media #{$breakpoint-above-tablet} {
      width: 80px;
    }

    img {
      width: 100%;
    }
  }

  &--image {
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;

    @media #{$breakpoint-above-tablet} {
      bottom: unset;
      left: unset;
      right: 96px;
      top: 72px;
      width: 496px;
    }

    img {
      width: 100%;
      margin: auto;
      max-height: 300px;
      object-fit: contain;

      @media #{$breakpoint-above-tablet} {
        height: auto;
        max-height: 100%;
      }
    }
  }

  &--cta {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    margin: 0;
    padding: 0;
  }

  &--enddate {
    font-family: $National2Regular;
    font-weight: $font-weight-normal;
    letter-spacing: -0.1px;
  }

  &--title {
    color: $white;
    font-family: $National2Condensed;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 0.5px;
    line-height: 1;
    margin-bottom: 16px;
    overflow-wrap: break-word;
    text-transform: uppercase;
    word-break: break-word;

    @media #{$breakpoint-above-tablet} {
      font-size: 40px;
      letter-spacing: 1px;
      margin-bottom: 24px;
    }
  }

  &--tag {
    border-radius: 4px;
    font-size: 16px;
    line-height: 1;
    padding: 4px 8px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 16px;

    @media #{$breakpoint-above-tablet} {
      margin-bottom: 24px;
    }

    &-product {
      background-color: $Brand-Red;
    }

    &-disposition {
      background-color: transparent;
      border: 1px solid $white;
    }
  }

  &--active-label {
    align-items: center;
    background: $light-green;
    border-radius: 4px;
    color: $Brand-Black;
    display: flex;
    font-family: $National2Regular;
    font-weight: $font-weight-500;
    font-size: 14px;
    line-height: 1.5;
    margin: 8px 0 0;
    padding: 4px 8px;
    width: max-content;

    &_icon {
      display: inline-block;
      height: 16px;
      margin-right: 3px;
      width: 16px;
    }
  }
}
