.sopac-medium-menu-container {
  background-color: $white;
  // border-top: 1px solid $Inactive-Gray;
  //margin-top: 8%;
  .medium-category-name {
    font-family: $National2Condensed;
    font-style: normal;
    font-weight: bold;
    // font-size: 44px;
    line-height: 58px;
    text-transform: uppercase;
    padding-top: 3.75rem;
    h2 {
      font-weight: 700;
      color: $black;
      font-size: 44px;
      line-height: 58px;
    }
    @media #{$breakpoint-below-tablet} {
      font-size: 36px;
      line-height: 36px;
      color: $Brand-Black;
      margin-left: 20px;
      width: 274px;
    }
    //padding-top: 8%;
    @media #{$breakpoint-below-tablet} {
      font-size: 36px;
      line-height: 36px;
      color: $Brand-Black;
      margin-left: 20px;
      width: 274px;
    }

    @media #{$breakpoint-below-and-above-medium-desktop} {
      padding-top: 54px;
    }
  }
  .dynamic-menu-time-desk {
    height: 21px;
    font-family: $National2Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    margin-top: -1%;
    color: #4d4d50;

    @media #{$breakpoint-ipad-12-pro-portrait} {
      margin-top: -1%;
    }
  }
  .medium-subcategory-name {
    font-family: $National2Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
    margin-top: 35px;
    @media #{$breakpoint-below-tablet} {
      display: flex;
      align-items: flex-end;
      letter-spacing: 1px;
      color: $Brand-Black;
      margin-left: 20px;
      margin-top: 24px;
    }
  }
  .product-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    @media #{$breakpoint-below-tablet} {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 30px;
    }
    .medium-menu-product-card {
      position: relative;
      width: 31%;
      // height: 475px;
      max-height: 315px;
      font-family: $National2Regular;
      background: $white;
      box-shadow: 0 4px 7px rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      margin-right: 16px;
      margin-bottom: 1.875rem;
      a {
        text-decoration: none;
      }
      @media #{$breakpoint-below-and-above-medium-desktop} {
        max-width: 315px;
      }
      @media #{$breakpoint-medium-desktop} {
        max-height: 315px;
      }
      @media #{$breakpoint-above-desktop} {
        //max-height: 480px;
      }

      @media #{$large-only} {
        margin-right: 2%;
      }
      @media #{$breakpoint-below-tablet} {
        position: relative;
        background: $white;
        box-shadow: 0 2px 9px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        width: 94%;
        height: 290px;
        padding: 15px 12px;
        margin-bottom: 15px;
        margin-right: 17px;
        margin-left: 18px;
        a {
          text-decoration: none;
        }
      }
      .medium-menu-product-image {
        width: 90%;
        margin-bottom: 15px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        @media #{$breakpoint-below-tablet} {
          position: absolute;
          //   left: 65.59%;
          right: 3.82%;
          top: 4.91%;
          bottom: 65.28%;
          width: 100px;
          height: 75px;
          border-radius: 4px;
        }
      }
      .specials-product-ribbon {
        position: absolute;
        top: 3%;
        left: 6%;
        transform: scaleX(1.5);
        @media #{$breakpoint-below-tablet} {
          position: absolute;
          left: 5%;
          transform: scaleX(1.5);
        }
      }
      .specials-ribbon-text {
        position: absolute;
        top: 4%;
        left: 0%;
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: $white;
        @media #{$breakpoint-below-tablet} {
          position: absolute;
          top: 4%;
          left: 0%;
        }
      }
      .discount-product-ribbon {
        position: absolute;
        top: 3%;
        left: -12%;
        transform: scaleX(1.5);
        z-index: 1;
      }
      .discount-ribbon-text {
        position: absolute;
        left: 0;
        top: 3%;
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: $Brand-Red;
        z-index: 1;
      }
      .exclusive-product-ribbon {
        position: absolute;
        top: 3%;
        left: -3%;
        transform: scaleX(2.5);
      }
      .menu-product-save {
        position: absolute;
        left: 87.86%;
        right: 3.57%;
        top: 2.11%;
        bottom: 92.84%;
        cursor: pointer;
        z-index: 1;
        @media #{$breakpoint-below-tablet} {
          position: absolute;
          left: 78.88%;
          right: 12.06%;
          top: 82.51%;
          bottom: 9.43%;
          cursor: pointer;
          display: none;
        }
      }
      .menu-card-content {
        margin-left: 11px;
        margin-right: 10px;
        margin-bottom: 20px;
        @media #{$breakpoint-below-tablet} {
          margin-left: auto;
          margin-right: auto;
          margin-bottom: auto;
        }
        .medium-menu-product-header {
          margin-bottom: 5px;
          font-style: normal;
          font-size: 16px;
          line-height: 24px;
          font-weight: 500;
          font-family: $National2Medium;
          color: $Brand-Black;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          @media #{$breakpoint-below-tablet} {
            width: 199px;
            font-family: $National2Regular;
            color: $Brand-Black;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            /* number of lines to show */
            -webkit-box-orient: vertical;
          }
        }
        .medium-menu-price-and-KJ {
          display: inline-block;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 5px;
          color: $Brand-Black;
          letter-spacing: -0.1px;
        }
        .Price-and-KJ-info {
          display: block;
        }
        .medium-menu-product-price {
          font-family: $National2Medium;
          color: $Brand-Black;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 5px;
        }
        .medium-menu-product-description {
          font-style: normal;
          font-weight: normal;
          font-family: $National2Regular;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: -0.1px;
          color: $Dark-Gray;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          /* number of lines to show */
          -webkit-box-orient: vertical;
        }
        &.menu-card-content-fav {
          .medium-menu-product-header {
            @media #{$breakpoint-below-tablet} {
              width: 58%;
            }
          }
          .medium-menu-product-price {
            .price-difference-container {
              .prev-price {
                text-decoration: line-through;
              }
              .new-current-price {
                color: #ff2e00;
              }
            }
          }
          .product-details-mobile-view {
            .medium-menu-product-description {
              min-height: 62px;
              width: 57%;
            }
            .price-veg-nonveg {
              margin-top: 15px;
              display: flex;
            }
            .heart-icon {
              padding-top: 3px;
            }
            .medium-menu-price-and-KJ {
              margin-left: 10px;
              margin-top: 1px;
            }
            .mobile-fav-menu-style,
            .mobile-fav-menu-style-unavailable {
              position: absolute;
              left: 87%;
              @media (min-width: 380px) {
                left: 90%;
              }
            }
          }
        }
      }
      .medium-menu-cart-button {
        display: flex;
        justify-content: center;
        //width: 80%;
        height: 44px;
        margin-top: 10px;
        //position: absolute;
        //left: 10%;
        //right: 10%;
        //bottom: 1rem;
        @media #{$breakpoint-below-tablet} {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          position: absolute;
          left: 4.41%;
          right: 25%;
          bottom: 5.66%;
          width: 68%;
        }
      }
    }
    .product-card-active {
      cursor: pointer;
      z-index: 50;
      @media #{$breakpoint-below-tablet} {
        text-decoration: none !important;
      }
    }
  }
  &.medium-menu-container-fav-menu {
    .product-container {
      margin-top: 20px;
      margin-right: 20px;
      @media #{$breakpoint-below-tablet} {
        margin-right: 40px;
      }
      .medium-menu-product-card {
        width: 280px;
        @media #{$breakpoint-below-tablet} {
          width: 100%;
        }
        .medium-menu-cart-button {
          margin-bottom: 20px;
        }
        .cart-btn-container {
          width: 100%;
          .edit-selection {
            text-decoration-line: underline;
            font-family: $National2Regular;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            bottom: 28px;
            position: relative;
            float: right;
          }
          .cardButtonOnly {
            bottom: 0;
          }
          .mobile-fav-menu-style,
          .mobile-fav-menu-style-unavailable {
            display: none;
          }
          @media #{$breakpoint-below-tablet} {
            display: flex;
            .cardButtonOnly {
              position: static;
            }
            .mobile-fav-menu-style {
              display: flex;
              position: absolute;
              right: 10%;
              bottom: 17%;
            }
            .mobile-fav-menu-style-unavailable {
              display: flex;
              position: absolute;
              bottom: 50px;
              right: 11.5%;
            }
            // img {
            // 	margin-left: -32px;
            // 	margin-top: 40px;
            // }
          }
        }
        @media #{$medium-only} {
          .cardwithQuantity {
            .mobile-fav-menu-style {
              bottom: 12%;
            }
          }
        }
        @media #{$mobile-only} {
          .quantityAddtocart {
            justify-content: flex-start;
            margin-left: -10px;
            .cart-btn-container {
              display: inline-flex;
              justify-content: center;
              //position: absolute;
              //bottom: 0;
              .cardButtonOnly {
                position: revert;
                width: 90%;
                .medium-menu-cart-button {
                  position: relative;
                  width: 90%;
                  left: 0;
                  bottom: 0;
                }
              }
              .mobile-fav-menu-style {
                margin-left: 0;
                //position: relative;
                right: 0;
              }
              .mobile-fav-menu-style-unavailable {
                position: relative;
                display: flex;
                top: 5px;
                right: 12px;
              }
              // img {
              // 	display: inline-flex;
              // 	margin-left: 20px;
              // 	margin-bottom: 20px;
              // 	margin-top: 0;
              // }
            }
          }
          .cardwithNoquantity {
            .cart-btn-container {
              .mobile-fav-menu-style {
                right: 3.82%;
              }
            }
          }
        }
        .medium-menu-product-image-fav {
          @media #{$breakpoint-below-tablet} {
            width: 123px;
            height: 93px;
          }
        }
      }
    }
  }
  &.medium-menu-container-fav-menu-disabled {
    .medium-menu-product-image-fav {
      opacity: 0.4;
    }
    .medium-menu-cart-button-disabled {
      background-color: #dbdbdb;
      border: none;
      //color: #494949;
      font-weight: bold;
    }
  }
}

.sopac-small-menu-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 30px;
  @media #{$mobile-only} {
    margin-top: 0px;
  }
  &:first-child {
    margin-top: 0;
    .small-category-name {
      margin-top: 60px;
      @media #{$mobile-only} {
        margin-top: 39px;
      }
    }
    .offsetFirstIndex .small-category-name {
      margin-top: 0;
      @media #{$mobile-only} {
        margin-top: 3px;
      }
    }
  }
  .product-view-section {
    display: flex;
    justify-content: flex-end;
    align-items: baseline;
    margin-right: 20px;
    margin-top: 2vw;
    margin-bottom: 10px;
    .product-view {
      font-family: $National2Regular;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 22px;
      text-align: right;
      color: $Dark-Gray;
      opacity: 0.5;
    }
    .product-view-up-icon {
      margin-left: 10px;
    }
    .product-view-carousal-icon {
      margin-left: 10px;
    }
  }
  .product-view-divider {
    border-top: 1px solid #e3e3e3 !important;
    width: 100%;
    margin-bottom: 30px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: auto !important;
  }
  .small-category-name {
    font-family: $National2Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 36px;
    text-transform: uppercase;
    color: $Brand-Black;
    margin-left: 4px;
    width: 274px;
    margin-top: 20px;
    @media #{$breakpoint-between-mob-tablet} {
      width: auto;
    }
  }
  .small-subcategory-name {
    font-family: $National2Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    display: flex;
    align-items: flex-end;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $Brand-Black;
    margin-left: 20px;
    margin-top: 24px;
  }
  .dynamic-menu-time-mob {
    height: 21px;
    font-family: $National2Medium;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #4d4d50;
    padding-left: 1.2%;
    margin-top: -1%;
  }
  .product-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    // z-index: 1;;
    @media (min-width: 768px) {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: normal;
    }
    .small-menu-product-card {
      position: relative;
      background: $white;
      box-shadow: 0 2px 9px rgba(0, 0, 0, 0.15);
      border-radius: 4px;
      border-radius: 8px;
      width: 97%;
      // height: 280px;//sopac
      min-height: 315px;
      // padding: 15px 15px; //sopac
      margin: 0 5px 30px 5px;
      @media #{$breakpoint-below-tablet} {
        a {
          text-decoration: none !important;
        }
      }
      .cardwithQuantity {
        justify-content: space-evenly;
        margin-left: 0;
        width: 70%;
        //column-gap: 4%;
        @media #{$medium-only} {
          //column-gap: 30%;
        }
      }
      @media screen and (max-width: 1022px) {
        .cardwithQuantity.loggedIN {
          width: 28%;
        }
      }
      .small-menu-product-image {
        // position: absolute;
        // left: 57.59%;
        // right: 3.82%;
        // top: 4.91%;
        bottom: 65.28%;
        // width: 123px;
        width: 100%;
        // height: 93px;
        // height: 252px;
        border-radius: 8px 8px 0 0;
        // @media #{$breakpoint-between-mob-tablet} {
        // 	position: absolute;
        // 	left: 68.59%;
        // 	width: 28%;
        // 	height: auto;
        // }
        transform: scale(1);
      }
      .specials-product-ribbon {
        position: absolute;
        top: 2%;
        left: 11%;
        transform: scaleX(1.8);
        // @media #{$breakpoint-between-mob-tablet} {
        // 	left: 4%;
        // }
      }
      .specials-ribbon-text {
        position: absolute;
        top: 3%;
        left: -3%;
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: bold;
        font-size: 18px;
        line-height: 20px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: $white;
      }
      .discount-product-ribbon {
        position: absolute;
        top: 3%;
        left: -8%;
        transform: scaleX(1.8);
        z-index: 1;
        @media #{$breakpoint-between-mob-tablet} {
          left: -5%;
        }
      }
      .discount-ribbon-text {
        position: absolute;
        left: 0;
        top: 3%;
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: $Brand-Red;
        z-index: 1;
      }
      .exclusive-product-ribbon {
        position: absolute;
        left: -2%;
        top: 3%;
        transform: scaleX(2.5);
      }
      .menu-product-save {
        position: absolute;
        left: 87.88%;
        //top: 37%;
        // right: 12.06%;
        // top: 83.51%;
        // bottom: 9.43%;
        cursor: pointer;
        z-index: 1;
        @media (min-width: 411px) and (max-width: 465px) {
          left: 84%;
        }
      }
      .menu-card-content {
        .small-menu-product-header {
          width: 199px;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          font-family: $National2Regular;
          color: $Brand-Black;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          // display: -webkit-box;
          // -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          margin-bottom: 5px;
          @media #{$breakpoint-tablet-only} {
            width: auto;
            // margin-top: 5%;
            padding: 16px;
          }
          @media #{$mobile-only} {
            // width: 56%;
            width: 100% !important;
            padding: 16px;
            font-weight: 500;
            font-family: $National2Medium;
          }
        }
        .small-menu-product-header-shift {
          width: 199px;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          font-family: $National2Regular;
          color: $Brand-Black;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          /* number of lines to show */
          -webkit-box-orient: vertical;
          margin-bottom: 5px;
          // margin-top: 31px;
          @media #{$breakpoint-between-mob-tablet} {
            width: auto;
          }
          @media #{$mobile-only} {
            // width: 56%;
            width: 100%;
            padding: 15px;
          }
        }
        .small-menu-price-and-KJ {
          display: inline-block;
          margin-left: 2px;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 24px;
          color: $Dark-Gray;
          margin-bottom: 5px;
          @media #{$breakpoint-between-mob-tablet} {
            margin-bottom: 15px;
          }
          @media #{$mobile-only} {
            font-family: $National2Regular;
          }
        }
        .small-price-and-KJ-info {
          display: block;
        }
        .small-menu-product-price {
          font-style: normal;
          font-family: $National2Regular;
          color: $Brand-Black;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          @media #{$breakpoint-between-mob-tablet} {
            margin-bottom: 20px;
          }
        }
        .small-menu-product-description {
          font-family: $National2Regular;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: -0.1px;
          color: $Dark-Gray;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          /* number of lines to show */
          -webkit-box-orient: vertical;
          margin-top: 5px;
          margin-bottom: 14px;
          width: 56%;
        }
        .PriceVegNonveg {
          display: flex;
          @media #{$breakpoint-between-mob-tablet} {
            margin-top: 25px;
          }
        }
        .hearticonDiv {
          padding-top: 4px;
        }
      }
      .small-menu-cart-button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 80%;
        height: 44px;
        // position: absolute;
        // left: 4.41%;
        // right: 25%;
        // bottom: 5.66%;
        @media (min-width: 769px) and (max-width: 1022px) {
          width: 62%;
        }
        @media #{$breakpoint-between-mob-tablet} {
          width: 62%;
        }
      }
    }
    .small-menu-product-card-long {
      height: 330px;
    }
    .product-card-active {
      cursor: pointer;
      z-index: 50;
      @media #{$breakpoint-below-tablet} {
        text-decoration: none !important;
        z-index: 0;
      }
      @media (min-width: 768px) {
        width: 48%;
      }
    }
    & .quantityAddtocart {
      .cardButtonOnly.loggedIN {
        @media (min-width: 342px) and (max-width: 1022px) {
          width: 89%;
          // margin-left: -42px;
        }
        @media (max-width: 340px) {
          width: 86%;
          left: -3%;
        }
      }
    }
  }
  .card-counter {
    display: none;
    position: absolute;
    right: 0;
    bottom: 0;
    color: #848282;
  }
}

.offsetWithLocalized {
  margin-top: 70px;
}

.offsetOtherIndex {
  margin-top: 62px;
  @media #{$mobile-only} {
    margin-top: 43px;
  }
}

.offsetFirstIndex {
  margin-top: 50px;
}

.offsetFirstIndexWithBanner {
  margin-top: 50px;
}

.product-serves {
  text-transform: capitalize;
}

@media #{$mobile-only} {
  .mob-plp-au {
    .mob-nav-row {
      height: 50px;
      padding: 5px 0 0 20px !important;
      border-top: 1px solid rgba(219, 219, 219, 0.7);
      border-bottom: 1px solid rgba(219, 219, 219, 0.7);
      & .mob-search-input {
        border-radius: 200px;
        padding: 0 18px 0 24px;
        margin-top: 2px;
      }

      & .mob-search-img {
        height: 19px;
      }

      .mob-outer-ul {
        margin-top: 8px;

        & li {
          &:first-child {
            margin-left: 17px;
          }

          & a {
            font-weight: 400;
            padding-bottom: 32px;
            position: relative;
            text-decoration: none !important;
            text-decoration-line: none !important;
          }

          & a.active {
            border-bottom: 3px solid $Brand-Black;
          }
        }
      }
    }
    .small-menu-product-header {
      padding: 15px;
      font-weight: 500;
      font-family: $National2Medium;
    }
    .small-menu-product-card {
      background: $white;
      box-shadow: 0 2px 9px rgba(0, 0, 0, 0.15);
      border-radius: 8px;
    }
    .slick-slide {
      width: 300px;
      margin-left: 5px;
      margin-right: 15px;
    }
    .mob-disclimer-content {
      margin: 0px 20px 40px 20px;

      .info-content-mobile {
        font-family: $National2Regular;
      }
    }
  }
}
@media #{$breakpoint-tablet-only} {
  .mob-plp-au {
    .mob-nav-row {
      padding-left: 20px;
      .mob-outer-ul {
        & li {
          & a.active {
            border-bottom: 3px solid $Brand-Black;
          }
        }
      }
    }
  }
}
.product-image-parent-container {
  width: 100%;
  // display: flex;
  padding-top: 75%; //to maintain aspect-ratio : 4/3
  justify-content: center;
  border-radius: 8px 8px 0 0;
  overflow: hidden;
  @media #{$breakpoint-above-tablet} {
    margin-bottom: 15px;
  }
  @include backgroundImage();
  img {
    width: 100%;
    //to maintain aspect-ratio : 4/3 - start for img
    top: 0 !important;
    left: 0 !important;
    bottom: 0 !important;
    right: 0 !important;
    position: absolute;
    //to maintain aspect-ratio : 4/3 -end for img
    margin: auto auto !important;
    display: block;
    transition: all 0.7s;

    -webkit-transition: all 0.7s;
    @include imageAnimation;
    @media #{$breakpoint-above-destop-wide-large-screen} {
      // width: 50% !important;
    }
    @media #{$breakpoint-above-tablet} {
      &:hover {
        -ms-transform: scale(1.1);
        transform: scale(1.1);
      }
    }
  }
}
.large-menu-container,
.carousal-menu-container {
  .product-image-parent-container {
    img {
      transform: scale(1);
    }
  }
}
.product-card-deactive {
  mix-blend-mode: normal;
  border: 1.5px solid #ff2e00;
  opacity: 0.9;
  cursor: not-allowed !important;
  pointer-events: none;
  & .unavailable-container {
    display: flex;
    & .unavailable-text {
      font-family: $National2Regular;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #494949;
    }
    & .unavailable-icon {
      margin-right: 5px;
    }
  }
  .product-image-parent-container {
    opacity: 0.3;
  }
  &.unavailable-now {
    background: $Alert-Yellow !important;
    border-color: $gold;
  }
}
.sopac-medium-catering-container {
  .product-container {
    .medium-menu-product-card {
      max-height: 325px !important;
    }
  }
}
.pdp-modal-toast {
  .Toastify__toast-container {
    @media #{$breakpoint-above-large-desktop} {
      bottom: 34% !important;
    }
  }
}

.special-event-time-nav {
  position: relative;
  padding: 20px;
  background: $Alert-Yellow;

  @media #{$breakpoint-above-tablet} {
    display: flex;
    justify-content: space-between;
    // width - to match adjacent components
    width: 96%;
    margin: 0 0 20px;
  }

  &.abridged {
    @media #{$breakpoint-below-tablet} {
      padding-bottom: 35px;
    }
  }
}

.special-event-time-field {
  white-space: nowrap;
}

@media #{$breakpoint-below-tablet} {
  .special-event-time-field {
    display: inline-block;

    &:last-child:before {
      display: inline-block;
      content: '-';
      padding-right: 5px;
    }
  }
}

.special-event-selected-time {
  font-size: 14px;
}

.special-event-time-slots {
  max-height: 5500px;
  margin-top: 10px;
  position: relative;

  @media #{$breakpoint-above-tablet} {
    flex-grow: 100;
    padding-left: 40px;
    margin: 0;
  }

  .abridged & {
    max-height: 55px;
    white-space: nowrap;
    overflow-y: auto;
    // right padding as visual clue it's the end of the list
    padding-right: 20px;

    @media #{$breakpoint-above-tablet} {
      white-space: normal;
      max-height: 38px;
      overflow: hidden;
      padding: 0 0 0 40px;
    }
  }

  .button {
    padding: 5px 15px;
    width: 95px;
    margin: 0 5px 5px 0;
  }

  &-show-more {
    position: absolute;
    right: 15px;
    bottom: 5px;
    border: 0;
    padding: 5px;
    background: transparent;
    font-size: 14px;

    @media #{$breakpoint-above-tablet} {
      right: 5px;
    }
  }
}
.medium-menu-container .medium-menu-product-image {
  transform: scale(1);
}
.medium-menu-product-price .medium-menu-vegOrNonVeg {
  @media #{$breakpoint-below-tablet} {
    padding-left: 4px;
    .Dot {
      background-color: #494949;
      height: 4.5px;
      width: 4.3px;
      display: inline-block;
      border-radius: 30px;
      margin-left: 4px;
      margin-bottom: 2px;
    }
  }
}
