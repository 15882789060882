.flexible-tout {
  background-color: $Mashed-Potato-Gray;
  color: $Brand-Black;
  border-radius: 8px;
  padding: 32px 16px;

  @media #{$breakpoint-above-tablet} {
    padding: 32px;
  }

  &.bgBlack {
    background-color: $Brand-Black;
    color: $white;
  }
  &.bgGray {
    background-color: $Mashed-Potato-Gray;
    color: $Brand-Black;
  }
  &.bgRed {
    background-color: $Brand-Red;
    color: $white;
  }

  &--container {
    padding: 24px;

    &.bgBlack {
      background-color: $Brand-Black;
    }
    &.bgGray {
      background-color: $Mashed-Potato-Gray;
    }
    &.bgRed {
      background-color: $Brand-Red;
    }
  }

  &--columns {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media #{$breakpoint-above-tablet} {
      flex-direction: row;
    }
  }

  &--column {
    flex-basis: fit-content;
    text-align: left;

    @media #{$breakpoint-above-tablet} {
      flex-basis: 50%;
    }

    &--1 {
      margin-left: 90px;
    }

    &--2 {
      text-align: center;

      @media #{$breakpoint-above-tablet} {
        text-align: right;
      }
    }
  }

  &--headline {
    font-family: $National2Condensed;
    font-size: 2rem;
    font-weight: 700;
    text-transform: uppercase;

    @media #{$breakpoint-above-tablet} {
      font-size: 2.5rem;
    }
  }

  &--pictogram-wrapper {
    float: left;
    margin-left: -90px;
  }

  &--button-container {
    margin-top: 24px;
    margin-bottom: 0;

    @media #{$breakpoint-above-tablet} {
      margin-top: 0;
    }
  }

  &--button {
    line-height: 3.75rem;

    @media #{$breakpoint-above-tablet} {
      line-height: 2.75rem;
    }
  }
}
